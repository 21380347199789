<template>
  <div>
    <el-form :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="订单号" prop="orderid">
        <el-input
          v-model="form.orderid"
          placeholder="请输入订单号"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="用户手机" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="请输入手机号"
          @keyup.enter="onSubmit"
          @change="handPhone"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="支付方式" prop="payway">
        <el-select v-model="form.payway" placeholder="全部" clearable>
          <el-option label="微信支付" value="0"></el-option>
          <!-- <el-option label="支付宝支付" value="1"></el-option>
          <el-option label="苹果内购" value="2"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="订单状态" prop="status">
        <el-select v-model="form.status" placeholder="全部" clearable>
          <el-option label="待付款" value="0"></el-option>
          <el-option label="已付款" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="邮寄状态" prop="mailstatus">
        <el-select v-model="form.mailstatus" placeholder="全部" clearable>
          <el-option label="待邮寄" value="1"></el-option>
          <el-option label="已邮寄" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-content">
      <div class="table-title">订单列表</div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column fixed prop="orderid" label="订单号" width="200">
        </el-table-column>
        <el-table-column
          prop="type"
          label="课程类型"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="goods"
          label="课程名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="levelid"
          label="所属级别"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户账号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="150" align="center">
        </el-table-column>
        <el-table-column
          prop="paytime"
          label="支付时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payway"
          label="支付来源"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          label="实付金额(元)"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mailstatus"
          label="邮寄状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          min-width="150"
          show-overflow-tooltip
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="
                scope.row.mailstatus === '待邮寄' &&
                type.indexOf('填写单号') > -1
              "
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >快递单号</el-button
            >
            <el-button
              v-else-if="type.indexOf('查看快递') > -1"
              @click="handleCoury(scope.row.id)"
              type="text"
              size="small"
              >查看快递</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
    </div>
    <el-dialog title="快递单号" v-model="dialogFormVisible" width="500px">
      <el-form :model="coury_form" label-width="100px" class="coury-form">
        <el-form-item label="快递公司：">
          <!-- <el-select v-model="coury_form.region" placeholder="请选择快递公司">
            <el-option label="顺丰快递" value="shanghai"></el-option>
          </el-select> -->
          <div>顺丰快递</div>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input
            v-model="coury_form.couriercode"
            placeholder="请输入快递单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息：">
          <el-input
            type="textarea"
            v-model="coury_form.remarks"
            :maxlength="200"
            :rows="6"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handCancel">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="快递单号" v-model="couryVisible" width="500px">
      <el-form label-width="100px">
        <el-form-item label="快递公司：">
          <div>{{ couryData.company }}</div>
        </el-form-item>
        <el-form-item label="快递单号：">
          <div>{{ couryData.couriercode }}</div>
        </el-form-item>
        <el-form-item label="备注信息：">
          <div>{{ couryData.remarks }}</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="couryVisible = false"
            >关 闭</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import { orderList, addCoury, lookCoury } from "@/api/apiList/order-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        orderid: "",
        phone: "",
        payway: "",
        status: "",
        mailstatus: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      coury_form: {
        id: "",
        couriercode: "",
        remarks: "",
      },
      couryData: {},
      dialogFormVisible: false,
      couryVisible: false,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      loading: true,
      type: "",
    };
  },
  methods: {
    handPhone() {
      this.$store.commit("changePhone", 0);
    },
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$store.commit("changePhone", 0);
    },
    handleClick(e) {
      this.coury_form = {
        id: e,
        couriercode: "",
        remarks: "",
      };
      this.dialogFormVisible = true;
    },
    handCancel() {
      this.dialogFormVisible = false;
    },
    submitForm() {
      this.$confirm("请仔细核对信息，提交后不能修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addCoury(this.coury_form).then((res) => {
          if (res.result === "200") {
            this.$message.success({
              message: res.data.info,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.handList();
          }
        });
      });
    },
    handleCoury(e) {
      let obj = {
        id: e,
      };
      lookCoury(obj).then((res) => {
        if (res.result === "200") {
          this.couryData = res.data;
          this.couryVisible = true;
        }
      });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handList() {
      orderList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            if (item.phone && this.type.indexOf("查看手机号") <= -1) {
              const reg = /^(\d{3})\d{4}(\d{4})$/;
              item.phone = item.phone.replace(reg, "$1****$2");
            }

            switch (item.type) {
              case 1:
                item.type = "主课";
                break;
              case 2:
                item.type = "小课包";
                break;
            }
            switch (item.levelid) {
              case 1:
                item.levelid = "U1-L1";
                break;
              case 2:
                item.levelid = "U2-L4";
                break;
            }
            switch (item.status) {
              case 0:
                item.status = "待支付";
                break;
              case 1:
                item.status = "已支付";
                break;
              case 2:
                item.status = "已支付";
                break;
            }
            switch (item.payway) {
              case 0:
                item.payway = "微信支付";
                break;
              case 1:
                item.payway = "支付宝支付";
                break;
              case 2:
                item.payway = "苹果内购";
                break;
            }
            switch (item.mailstatus) {
              case 0:
                item.mailstatus = "待邮寄";
                break;
              case 1:
                item.mailstatus = "待邮寄";
                break;
              case 2:
                item.mailstatus = "已邮寄";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    if (this.$store.state.phone > 0) {
      this.form.phone = this.$store.state.phone;
    } else {
      this.form.phone = "";
    }
    this.handList();
  },
};
</script>

<style lang="scss"></style>
