import { get, post } from "../request";

// 商品订单
export const orderList = (params) => get("/order/getOrderList", params);

// 填写快递单
export const addCoury = (params) => post("/order/addCode", params);

// 查看快递单
export const lookCoury = (params) => post("/order/getCode", params);

// 退款审核列表
export const getRefundList = (params) => get("/order/getRefundList", params);

// 退款审核详情
export const getRefundInfo = (params) => get("/order/getRefundInfo", params);

// 退款审核
export const auditRefund = (params) => post("/order/auditRefund", params);

// 订单退款
export const orderRefund = (params) => post("/order/refund", params);
